import { APP_USER_ID_REQUEST_HEADER_NAME } from "@/enum/request.header.name.enum.ts";

export const THEME_MODE = "themeMode"
export const SIDEBAR_DRAWER_IS_OPENED = "sidebarDrawerIsOpened"
export const AUDIO_EDITOR_PREFERENCES = "audioEditorPreferences"
export const AUDIO_EDITOR_VISIBLE = "audioEditorVisible"
export const AUDIO_MUTED = "audioMuted"
export const AMPLITUDE_SCALE_SLIDER_VISIBLE = "amplitudeScaleSliderVisible"
export const THEME_COLOR = "themeColor"
export const AUDIO_VOLUME = "audioVolume"
export const EXPLORER_FULLSCREEN = "explorerFullscreen"
export const AUDIO_PLAYMODE = "audioPlaymode"
export const AUDIO_TRACK_DRAWER_OPENED = "audioTrackDrawerOpened"
export const WAVEFORM_OVERVIEW_VISIBLE = "waveformOverviewVisible"
export const WAVEFORM_TOOLS_OPENED = "waveformToolsOpened"
export const WAVEFORM_COMMENTS_VISIBLE = "waveformCommentsVisible"
export const WAVEFORM_COMMENTS_LIST_VISIBLE = "waveformCommentsListVisible"
export const WAVEFORM_AXIS_TIME_VISIBLE = "waveformAxisTimeVisible"
export const EXPLORER_DATA_GRID_SORTING_MODEL = "explorerDataGridSortingModel"
export const LIKES_DATA_GRID_SORTING_MODEL = "likesDataGridSortingModel"
export const EXPLORER_DATA_GRID_COLUMN_VISIBILITY_MODEL = "explorerDataGridColumnVisibilityModel"
export const LIKES_DATA_GRID_COLUMN_VISIBILITY_MODEL = "likesDataGridColumnVisibilityModel"
export const WAVEFORM_AMPLITUDE_SCALE = "waveformAmplitudeScale"

export const APP_USER_ID = APP_USER_ID_REQUEST_HEADER_NAME
export const MODAL_STATE = "modalState"
export const API_AUTH_COOKIE_NAME = "apiAuthorization"
export const API_MERCURE_COOKIE_NAME = "mercureAuthorization"
